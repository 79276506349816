<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：批号库存明细</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="2" >
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round  @click="findDetailWarehouse" size="mini">搜索查询</el-button>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                                <el-button style="margin-left: 9px; margin-top: 8px;margin-bottom: 8px; " type="success" plain size="mini"
                                           @click="showDialogFormVisible = true,checkedEvent()">批量设置可用数
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openType({path: '/intoTheWarehouse', query: {bill_id: $refs.xGrid.getCurrentRecord().bill_id_stock}})" size="mini">修改进仓单
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <vxe-modal width="420px" title="商品分类设置" v-model="showDialogFormVisible" :esc-closable="true" :lock-view="false" :mask="false" resize>
                    <el-form :model="changeForm">
                        <el-alert
                                :title="'当前选择 '+checkedGoodsIds.length+' 个商品！'"
                                type="success"
                                :closable="false">
                        </el-alert>
                        <el-form-item label="支数（可用量）:" label-width="180px">
                            <el-input-number v-model="changeForm.singleCount" size="mini"
                                             :controls="false" :precision="2" :min="0"></el-input-number>
                        </el-form-item>
                    </el-form>
                    <div align="center" style="margin-top: 10px">
                        <el-button size="mini" @click="showDialogFormVisible = false">取 消</el-button>
                        <el-button size="mini" type="primary" @click="batchUpdateSingleCount()">确 定</el-button>
                    </div>
                </vxe-modal>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="detailWarehouseBatch"
                                size="mini"
                                align="right"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-hover-row
                                highlight-current-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :custom-config="{storage:true}"
                                :mouse-config="{selected: false}"
                                :checkbox-config="{range:true}"
                                :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </div>
</template>

<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500
               },
                tableColumn: [
                   {type: 'checkbox',  title: '', width: 50},
                   {
                        field:'id', width: 100, title: 'ID', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                       }
                   },
                    {
                        field:'batch_number', width: 170, title: '批号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    },
                    {
                        field:'category', width: 100, title: '类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    }, {
                        field:'specification_name', width: 190, title: '规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                       }
                   }, {
                        field:'company_name', width: 190, title: '供应商', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    },
                    {
                        field:'full_thick', title: '足厚', width: 50, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    },
                    {
                        field:'single_count', width: 100, title: '支数(可用量)',
                        className: 'sumColorRed',
                        editRender: {name: 'ElInputNumber',props: {controls:false}, events: {blur: this.remarkEvent}},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                       }
                   },{
                        field:'bill_id_stock', width: 190, title: '进仓单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    },
                   /* {
                        field:'single_lock', width: 100, title: '支数(锁定)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                       }
                   },
                    {
                        field:'single_count_total', width: 100, title: '支数(总库存)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                       }
                   },*/
                    {
                        field:'warehouse_name', width: 100, title: '仓库', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                       }
                   },
                    {
                        field:'is_order', width: 100, title: '是否订货', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    },
                    {
                        field:'bill_stock_date', width: 140, title: '进仓单日期',formatter: ({cellValue}) => {return this.$_formatDate(cellValue,'yyyy-MM-dd')},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    },
                    {
                        field:'a_side',visible:false, width: 100, title: 'A边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    }, {
                        field:'b_side',visible:false, width: 100, title: 'B边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    }, {
                        field:'thickness', visible:false,width: 100, title: '厚度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    }
                    , {
                        field:'length_extent', visible:false,width: 100, title: '长度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    }, {
                        field:'theoretical_weight', visible:false,width: 100, title: '理论重量', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseBatch/searchHeard')
                        }
                    }
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {
                    'id' : 'desc',
                },
                showDialogFormVisible: false,
                checkedGoodsIds: [],
                checkedGoods: [],
                changeForm:{
                    singleCount:0,
                }
           }
       },
        methods: {
            batchUpdateSingleCount() {
                this.$axios({
                    method: 'post',
                    url: '/admin/detailWarehouseBatch/batchUpdateSingleCount',
                    data: {
                        singleCount: this.changeForm.singleCount,
                        checkedGoods: this.checkedGoods,
                        checkedGoodsIds: this.checkedGoodsIds,
                    },
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                        });
                        this.showDialogFormVisible = false;
                        this.findDetailWarehouse();
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            checkedEvent() {
                this.checkedGoodsIds = this.getSelectionEvent('xGrid');
                this.checkedGoods = this.$refs['xGrid'].getCheckboxRecords();
                //console.log('checkedGoodsIds',this.checkedGoodsIds)
            },
            getSelectionEvent(ref) {
                const $grid = this.$refs[ref];
                const selectRecords = $grid.getCheckboxRecords();
                let ids = selectRecords.map((item) => {
                    //console.log(item)
                    return item.id
                });
                return ids;
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.findDetailWarehouse()
            },
            findDetailWarehouse(){
                this.$axios({
                    method: 'POST',
                    url: '/admin/detailWarehouseBatch',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                        pickerDate:this.pickerDate,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.list
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            remarkEvent({row}) {
                //console.log(row)
                this.checkedGoodsIds = [];
                this.checkedGoodsIds.push(row.id);
                this.changeForm.singleCount = row.single_count;
                this.batchUpdateSingleCount();
            },
       },
        created() {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.findDetailWarehouse();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
